<template>
  <div>
    <div v-if="state===states.AccountSelect">
      <b-form-input
        v-model="searchText"
        class="search-text"
        type="text"
        debounce="150"
        placeholder="Search Name, Email"
        autofocus
        autocomplete="off"
        @update="filterAccounts"
      ></b-form-input>

      <table class="table table-google table-accounts mt-2">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email Address</th>
            <th>Admin</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="account in filteredAccounts" :key="account.id">
            <!-- display_name -->
            <td>
              <b-form-input
                v-if="account._editMode"
                v-model="account.display_name"
                size="sm"
                placeholder="Display name"
                @keyup.enter="saveAccountChanges(account)"
                :disabled="IsLoading"
              ></b-form-input>
              <span v-else>
                {{ account.display_name }}
              </span>
            </td>
            <!-- login -->
            <td>
              <b-form-input
                v-if="account._editMode"
                v-model="account.login"
                size="sm"
                placeholder="Login"
                @keyup.enter="saveAccountChanges(account)"
                :disabled="IsLoading"
              ></b-form-input>
              <span v-else>
                {{ account.login }}
              </span>
            </td>
            <!-- admin -->
            <td class="row-fit">
              <div v-if="account._editMode">
                <b-form-checkbox
                  v-model="account.admin"
                  :value="true"
                  :unchecked-value="false"
                  :disabled="IsLoading"
                ></b-form-checkbox>
              </div>
              <div v-else>
                <i v-if="account.admin" class="fas fa-user-shield" title="Admin"></i>
              </div>
            </td>
            <!-- action -->
            <td class="text-nowrap row-fit">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                @click="managePermissions(account)"
                title="Manage permissions"
              >
                Manage permissions
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="state == states.AccountPermissions">
      <button
        :disabled="IsLoading"
        type="button"
        class="btn btn-primary btn-sm"
        @click="closeAccountPermissions"
      >Close</button>

      <span class="d-inline align-middle ml-3">
        Editing permissions for <span class="font-weight-bold">{{ account.login }}</span>
      </span>

      <permissions-select
        v-if="HasAccountMandants"
        class="mt-3"
        :mandants="mandants"
        :account="account"
        :accountMandants="accountMandants"
        @mandantAdded="mandantAdded"
        @mandantRemoved="mandantRemoved"
        @loadingStarted="loadingStarted"
        @loadingEnded="loadingEnded"
      />
      <div v-else class="mt-3">Loading permissions</div>
    </div>
  </div>
</template>

<script>
const _ = require('lodash');

const PermissionsSelect = () => import('@/views/admin/PermissionsSelect.vue');

export default {
  name: 'Permissions',
  components: {
    PermissionsSelect,
  },
  computed: {
    IsLoading() {
      return this.loadingCount > 0;
    },
    HasAccount() {
      return Object.keys(this.account).length > 0;
    },
    HasAccountMandants() {
      return this.accountMandants !== null;
    },
  },
  data() {
    return {
      loadingCount: 0,
      state: 0,
      states: {
        AccountSelect: 0,
        AccountPermissions: 1,
      },
      accounts: [],
      filteredAccounts: [],
      searchText: '',
      account: {},
      accountMandants: [],
      mandants: [],
    };
  },
  methods: {
    reload() {
      this.fetchAccounts();
      this.fetchMandants();
      if (this.HasAccount) this.fetchAccountMandants(this.account);
    },
    filterAccounts() {
      this.filteredAccounts = this.accounts.filter((account) => (
        account.login.toLowerCase().includes(this.searchText.toLowerCase())
          || account.display_name.toLowerCase().includes(this.searchText.toLowerCase())
      ));
      this.filteredAccounts = _.sortBy(this.filteredAccounts, 'display_name');
    },
    fetchAccounts() {
      if (this.IsLoading) return;
      this.loadingCount++;
      this.$http
        .get('/accounts')
        .then((res) => {
          this.accounts = res.body.accounts;
          this.filterAccounts();
        })
        .catch((err) => {
          alert(`Failed to fetch accounts: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchMandants() {
      this.loadingCount++;
      this.$http
        .get('/mandants')
        .then((res) => {
          res.body.mandants.forEach((mandant) => {
            mandant.loading = false;
          });
          this.mandants = res.body.mandants;
        })
        .catch((err) => {
          alert(`Failed to fetch mandants: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchAccountMandants(account) {
      this.accountMandants = null;
      this.loadingCount++;
      this.$http
        .get(`/account_mandants/${account.id}`)
        .then((res) => {
          this.accountMandants = res.body.accountMandants;
        })
        .catch((err) => {
          alert(`Failed to fetch account mandants: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    managePermissions(account) {
      this.account = account;
      this.fetchAccountMandants(account);
      this.state = this.states.AccountPermissions;
    },
    closeAccountPermissions() {
      this.account = {};
      this.state = this.states.AccountSelect;
    },
    mandantAdded(mandant) {
      this.accountMandants.push(mandant.code);
    },
    mandantRemoved(mandant) {
      this.accountMandants = this.accountMandants.filter(
        (code) => code !== mandant.code,
      );
    },
    loadingStarted() {
      this.loadingCount++;
    },
    loadingEnded() {
      this.loadingCount--;
    },
  },
  created() {
    this.reload();
  },
};
</script>

<style lang="scss" scoped>
.search-text,
.table-accounts {
  max-width: 1000px;
}
</style>
